import { createFullResourceUrl, getBaseUrl } from './common-utils';

const getFileName = (path) => {
  const splitIndex = path.lastIndexOf('/') + 1;
  return path.substring(splitIndex);
};

export const LoadJs = /** @class */ (function () {
  function LoadJs(config) {
    this.value = null;
    this.browserAgent = this.getAgent();
    this.config = config;
    this.body =
      document.getElementsByTagName('body')[0] || document.documentElement;
    this.defaultBaseUrl = getBaseUrl();
  }
  LoadJs.prototype.getAgent = function () {
    return 'other';
  };
  LoadJs.start = function (config) {
    return Promise.resolve(new LoadJs(config));
  };
  LoadJs.prototype.add = function (...paths) {
    let url = '';
    if (paths.length === 1) {
      url = createFullResourceUrl(this.defaultBaseUrl, ...paths);
    } else {
      url = createFullResourceUrl(...paths);
    }
    return this.load(url);
  };
  LoadJs.prototype.asyncAdd = function () {};
  LoadJs.prototype.load = function (url, isSync = true) {
    // create script for adding into the body
    const fileName = getFileName(url);
    const isCSS = fileName.indexOf('.css') > -1;
    let ele;
    if (isCSS) {
      ele = document.createElement('link');
      ele.rel = 'stylesheet';
      ele.href = url;
    } else {
      ele = document.createElement('script');
      ele.charset = 'utf-8';
      ele.type = 'text/javascript';
      ele.id = url;
      ele.src = url;
      if (!isSync) {
        ele.async = true;
      }
    }
    if (ele && ele.src) {
      // fix zoomdev.us env cache not work after Chrome bug with header Cross-Origin-Embedder-Policy
      ele.crossOrigin = 'anonymous';
    }
    if (this.config?.ondemandAssetsSRI[fileName]) {
      // ele.integrity = this.config?.ondemandAssetsSRI[fileName];
      ele.crossOrigin = 'anonymous';
    }
    const that = this;
    return new Promise(function (resolve, reject) {
      let done = false;
      const loadFunction = function () {
        if (
          !done &&
          (!ele.readyState ||
            ele.readyState === 'loaded' ||
            ele.readyState === 'complete')
        ) {
          done = true;
          // Handle memory leak in IE
          ele.onload = null;
          ele.onreadystatechange = null;
          resolve(that);
        }
      };
      ele.onload = loadFunction;
      ele.onreadystatechange = loadFunction;
      ele.onerror = reject;
      that.body.appendChild(ele);
    });
  };
  return LoadJs;
})();
