export const SHARER_STATUS_ENUM = {
  ING: 0,
  ED: 1,
  PAUSED: 2,
};

export const SHARER_PERMISSION_ENUM = {
  HOST_SHARE: 0,
  ALL_SHARE: 1,
  HOST_GRAB: 2,
  ALL_GRAB: 3,
};

export const SHARER_PERMISSION_CODE = {
  CMM_SHARE_SETTING_LOCK_SHARE: 1, // Only host can share, the same as "lock share"
  CMM_SHARE_SETTING_HOST_GRAB: 0, // Anyone can share, but one sharing only at one moment, and only host can grab other's sharing
  CMM_SHARE_SETTING_ANYONE_GRAB: 2, // Anyone can share, but one sharing only at one moment, and anyone can grab other's sharing
  CMM_SHARE_SETTING_MULTI_SHARE: 3, // Anyone can share, Multi-share can exist at the same time
};

export const POP_UP_BOX_TYPE_ENUM = {
  POP_UP_BOX_DISABLED_SHARE1: 0,
  POP_UP_BOX_DISABLED_SHARE2: 1,
  POP_UP_BOX_START_SHARE: 2,
};

// event type
export const EVT_TYPE_BASE = 0x1000;
export const EVT_TYPE_SHARING_BASE = 0x4000;

export const EVT_TYPE_WS_CONF_LOCK_SHARE_REQ = 73 | EVT_TYPE_BASE;
export const EVT_TYPE_WS_CONF_ATTRIBUTE_INDICATION = 2 | EVT_TYPE_BASE | 0x0f00; // bLocked, bRecord,  etc.
export const WS_SHARING_DATA_RECEIVE_INDICATION =
  9 | EVT_TYPE_SHARING_BASE | 0x0f00; // 20233
export const EVT_TYPE_WS_SHARING_SSRC_INDICATION =
  3 | EVT_TYPE_SHARING_BASE | 0x0f00;

export const EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ = 25 | EVT_TYPE_SHARING_BASE;

export const EVT_TYPE_WS_SHARING_PAUSE_REQ = 1 | EVT_TYPE_SHARING_BASE;

export const EVT_TYPE_WS_SHARING_RESUME_REQ = 3 | EVT_TYPE_SHARING_BASE;

export const EVT_TYPE_WS_SHARING_UNSUBSCRIBE_REQ = 33 | EVT_TYPE_SHARING_BASE;
export const EVT_TYPE_WS_SHARING_SUBSCRIBE_REQ = 31 | EVT_TYPE_SHARING_BASE;
export const EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ = 5 | EVT_TYPE_SHARING_BASE;
export const EVT_TYPE_WS_SHARING_REMOTE_CONTROL_INDICATION =
  7 | EVT_TYPE_SHARING_BASE; // 16391
export const EVT_TYPE_WS_SHARING_REMOTE_CONTROLLER_GRAB =
  9 | EVT_TYPE_SHARING_BASE; // 16393
export const EVT_TYPE_WS_SHARING_REMOTE_CONTROLLER_GRAB_INDICATION =
  11 | EVT_TYPE_SHARING_BASE; // 16395
export const EVT_TYPE_WS_SHARING_FIRST_FRAME_DECODING_OK_REQ =
  37 | EVT_TYPE_SHARING_BASE;
export const WS_SHARING_MUTE_SHARE_AUDIO_REQ = 39 | EVT_TYPE_SHARING_BASE; // 16423
export const WS_SHARING_SHARE_BOROOMS_REQ = 41 | EVT_TYPE_SHARING_BASE; // 16425

export const CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT = 15;
export const DESKTOP_SHARING_CAPTURE_SUCCESS = 16;
export const START_SHARING = 23;
export const STOP_SHARING = 24;
export const CHANGE_CURRENT_SHARING_ACTIVE_SSRC = 36;
export const START_REMOTE_CONTROL = 26;
export const UPDATE_REMOTE_CONTROL_PROPERTIES = 27;
export const CANCEL_REMOTE_CONTROL = 28;
export const START_DESKTOP_SHARING = 30;
export const STOP_DESKTOP_SHARING = 31;
export const PAUSE_DESKTOP_SHARING = 32;
export const RESUME_DESKTOP_SHARING = 33;
export const CHECK_CHROME_SHARING_EXTENSION = 34;
export const SWITCH_CANVAS_FOR_SHARING_CAPTURE = 35;
export const RESEND_REMOTE_CONTROL_POSITION_PDU = 41;
export const UPDATE_SHARING_DECODE_PARAM = 44;
export const CHECK_CHROME_SHARING_EXTENSION_RESPONSE = 17;
export const USER_STOP_DESKTOP_SHARING = -15;
export const USER_CANCEL_PERMISSION_REQUEST = -16;
export const DESKTOP_SHARING_CHROME_EXTENSION_UNINSTALLED = -17;
export const DESKTOP_SHARING_PERMISSION_DENIED = -18;
export const DESKTOP_SHARING_TIME_OUT = -19;
export const DESKTOP_SHARING_ERROR = -20;
export const DESKTOP_SHARING_SYSTEM_ERROR = -22;

export const GLOBAL_FOOTER_TOOLBAR_HEIGHT = 52;
export const SHARER_CONTROLBAR_HEIGHT = 59;

export const SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID =
  'sharer-client-sharing-canvas';
export const SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID =
  'sharer-client-sharing-video';

export const TESLA_SHAREE_MAX_HEIGHT = 670;

export const START_SHARING_WHITEBOARD = 30.1;
export const STOP_SHARING_WHITEBOARD = 31.1;
