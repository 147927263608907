import { AVSocketService } from '../../global/av-socket-service';
import { getAudioConfigFromUserAgent } from './utils';
import * as AVNotifyAPPTypes from '../../constants/AVNotifyAPPTypes';
import * as AVNotifyMediaSDKTypes from '../../constants/AVNotifyMediaSDKTypes';
import eb from '../../global/event-bus';
import { PREVIEW_CANVAS, PREVIEW_EVENT } from './consts';
import { isYealink, isMTRAndroid, getVideoDPI } from '../../global/util';
import { AVSocketInboundMsg, infoLog } from '../../global/web-client-logger';
import { globalVariable } from '../../global/global-variable';
import { canConnectAVSocket } from '../../global/service';
import { easyStore } from '../../global/easy-store';
import { makeLogger } from '../../global/logger';
import { PERFORMANCE_MARK, performanceMark } from '../../performance';
import { sendRWGMessage } from '../../tasks/global/websocket/webclient-websocket';
import { LOCALSTORAGE_KEYS } from '../../global/constant';
import { getBackgroundConfigFromStorage, prepareBg } from './background';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../global/logger/log-service/avs-laplace-telemetry';
import { AVNotifyAPPTypeNames } from '../../constants/media-sdk-type-string';
import { safeJsonStringifyWithNoNest } from '../../global/utils/safe-json';
import deviceManager from '../../device-manager';
import { isExcludedMediaCallback } from '../../global/logger/is-laplace-enabled';
import { Job } from '@zoom/common-utils';
import { JOB_ENUM } from '../../job_enum';

export const audioLogger = makeLogger(['Preview', 'Audio']);
const mediaLogger = makeLogger([AVS_TAGS.mainTag]);
const videologger = makeLogger(['Preview', 'Video']);
const VIDEO_LOG = {
  VIDEO_ENCODE: 'video encode success preview page',
  START_VIDEO: 'start video success preview page',
};

class AVSocket {
  constructor(avServiceInstance) {
    this.socketCallBack = this.socketCallBack.bind(this);
    if (avServiceInstance && !avServiceInstance.isDestroyed) {
      this.serviceInstance = avServiceInstance;
      this.serviceInstance.updateSocketCallback(this.socketCallBack);
    } else if (canConnectAVSocket()) {
      this.createAVSocket();
    }
  }

  createAVSocket() {
    infoLog('>>>>>>>> CREATE JSMEDIASKD INSTANCE <<<<<<<<');
    const isPreviewMode = true;
    this.serviceInstance = new AVSocketService(
      false,
      this.socketCallBack,
      true,
    );
    globalVariable.avSocket = this.serviceInstance;
    const meeting = {
      currentUser: { userRole: 5 },
      meetingJoinStatus: 'joined',
      meetingNumber: '',
      conID: '',
      svcUrl: '',
    };
    const tasks = [];
    if (Job.check(JOB_ENUM.RECV_PREVIEW_INFO)) {
      tasks.push(Job.watch(JOB_ENUM.RECV_PREVIEW_INFO));
    }
    Promise.all(tasks)
      .then(() => {
        this.serviceInstance.connectSocket(
          meeting,
          {},
          {
            isClientEnablehardwareAccelerationForSendingVideo:
              easyStore.easyGet(
                LOCALSTORAGE_KEYS.webClient_enableHardwareAccelerationForSendingVideo,
              ) ?? true,
          },
          isPreviewMode,
        );
      })
      .catch((error) => {
        if (error === 'the AVSocketService instance is destroyed!') {
          mediaLogger.warn(error);
        }
      });
  }

  socketCallBack(type, data) {
    // eslint-disable-next-line @babel/new-cap
    AVSocketInboundMsg(JSON.stringify({ type, data }), type);
    if (isExcludedMediaCallback(type)) {
      //[3.5.0][MediaSDK_Callback] js media socket callback + [type] + [data]
      avsLogReport(
        `mediaSDK callback [preview] [${
          AVNotifyAPPTypeNames[type] ?? type
        }] \n${safeJsonStringifyWithNoNest(data)})}`,
        AVS_TAGS.MediaSDK_Callback,
      );
    }
    switch (type) {
      case AVNotifyAPPTypes.INIT_SUCCESS_AUDIO: {
        if (data === AVNotifyAPPTypes.INIT_SUCCESS_AUDIO_ENCODE) {
          audioLogger.log('init audio encode success', ['AUDIO INIT FLOW']);
          eb.emit(PREVIEW_EVENT.AUDIO_INIT_SUCCESS);
        }
        break;
      }
      case AVNotifyAPPTypes.INIT_SUCCESS_VIDEO: {
        if (data === AVNotifyAPPTypes.INIT_SUCCESS_VIDEO_ENCODE) {
          eb.emit(PREVIEW_EVENT.VIDEO_INIT_SUCCESS);
          videologger.log(VIDEO_LOG.VIDEO_ENCODE);
        }
        break;
      }
      case AVNotifyAPPTypes.USER_GRANT_AUDIO_CAPTURE: {
        audioLogger.log('device audio stream captured', ['AUDIO CAPTURE FLOW']);
        eb.emit(PREVIEW_EVENT.AUDIO_CONNECT_SUCCESS);
        deviceManager.emit('DEVICE_PERMISSION_MAY_GRANT');
        break;
      }
      case AVNotifyAPPTypes.START_VIDEO_STREAM_IN_VB_SETTING_SUCCESS:
      case AVNotifyAPPTypes.START_VIDEO_STREAM_IN_MASK_SETTING_SUCCESS: {
        deviceManager.emit('DEVICE_PERMISSION_MAY_GRANT');
        eb.emit(PREVIEW_EVENT.VIDEO_START_SUCCESS);
        videologger.log(VIDEO_LOG.START_VIDEO);
        break;
      }
      case AVNotifyAPPTypes.AUDIO_PREVIEW_ASN: {
        eb.emit(PREVIEW_EVENT.AUDIO_PREVIEW_ASN);
        break;
      }
      case AVNotifyAPPTypes.JOIN_COMPUTER_AUDIO_COMPLETE: {
        audioLogger.log('audio join complete', ['AUDIO JOIN FLOW']);
        break;
      }
      case AVNotifyAPPTypes.LEAVE_COMPUTER_AUDIO_COMPLETE: {
        audioLogger.log('audio leave complete', ['AUDIO JOIN FLOW']);
        eb.emit(PREVIEW_EVENT.AUDIO_LEAVE_SUCCESS);
        break;
      }
      case AVNotifyAPPTypes.USER_CAMERA_IS_TAKEN_BY_OTHER_PROGRAMS: {
        eb.emit(PREVIEW_EVENT.CAMERA_IS_TAKEN);
        break;
      }
      case AVNotifyAPPTypes.USER_FORBIDDED_CAPTURE_AUDIO: {
        eb.emit(PREVIEW_EVENT.AUDIO_FORBIDDEN);
        break;
      }
      case AVNotifyAPPTypes.USER_FORBIDDED_CAPTURE_VIDEO: {
        eb.emit(PREVIEW_EVENT.VIDEO_FORBIDDEN);
        break;
      }
      case AVNotifyAPPTypes.PREVIEW_INIT_VIDEO_DECODE_SUCCESS: {
        break;
      }
      case AVNotifyAPPTypes.PREVIEW_INIT_AUDIO_DECODE_SUCCESS: {
        break;
      }
      case AVNotifyAPPTypes.SEND_MESSAGE_TO_RWG:
      case AVNotifyAPPTypes.REQUEST_AUDIO_BRIDGE_TOKEN: {
        if (data) {
          sendRWGMessage(data);
        }
        break;
      }
      case AVNotifyAPPTypes.WCL_AUDIO_BRIDGE_FIRST_RECV_DATA: {
        globalVariable.avSocket?.setPeerConnectionRecvReady(true);
        performanceMark(PERFORMANCE_MARK.inPreview_audioBridgeDecodeSuccess);
        // audioBridge decode success
        break;
      }
      case AVNotifyAPPTypes.WCL_AUDIO_BRIDGE_CAN_SEND_DATA: {
        globalVariable.avSocket?.setPeerConnectionSendReady(true);
        performanceMark(PERFORMANCE_MARK.inPreview_audioBridgeEncodeSuccess);
        // audioBridge encode success
        break;
      }
    }
  }

  sendSocket(...args) {
    return this.serviceInstance.sendSocket(...args);
  }

  connectAudio(isAudioOn) {
    const { activeMicrophone, activeSpeaker } = deviceManager.getDeviceState();
    const joinAudioParams = {
      isPreviewMode: true,
      CaptureAudio: true,
      CaptureAudioInfo: {
        ssrc: 0,
        AudioSelectValue:
          activeMicrophone === 'default' ? null : activeMicrophone,
        defaultMuted: !isAudioOn,
      },
      speakerInfo: {
        defaultDeviceId: activeSpeaker,
      },
      checkAutoplay: false,
      disableAudioAGC:
        getAudioConfigFromUserAgent('agc') || (isMTRAndroid() && isYealink()),
      disableNoiseSuppression:
        getAudioConfigFromUserAgent('ns') || (isMTRAndroid() && isYealink()),
      disableBrowserAec:
        getAudioConfigFromUserAgent('aec') || (isMTRAndroid() && isYealink()),
    };
    audioLogger.log(`join audio info: ${JSON.stringify(joinAudioParams)}`, [
      'AUDIO JOIN FLOW',
    ]);
    return this.sendSocket(
      AVNotifyMediaSDKTypes.JOIN_COMPUTER_AUDIO,
      joinAudioParams,
    );
  }

  leaveAudio() {
    audioLogger.log('leave audio', ['AUDIO JOIN FLOW']);
    return this.sendSocket(AVNotifyMediaSDKTypes.LEAVE_COMPUTER_AUDIO, null);
  }

  muteAudio() {
    audioLogger.log('audio mute', ['AUDIO CAPTURE FLOW']);
    return this.sendSocket(AVNotifyMediaSDKTypes.STOP_CAPTURE_AUDIO, {
      ssrc: 0,
    });
  }

  changeMicrophone(deviceId) {
    return this.sendSocket(AVNotifyMediaSDKTypes.CHANGE_AUDIO_MIC, {
      srrc: 0,
      AudioSelectValue: deviceId === 'default' ? null : deviceId,
    });
  }

  unmuteAudio() {
    audioLogger.log('audio unmute', ['AUDIO CAPTURE FLOW']);
    return this.sendSocket(AVNotifyMediaSDKTypes.ADD_CAPTURE_AUDIO, {
      ssrc: 0,
      AudioSelectValue: 0,
    });
  }

  startVideo() {
    const { activeCamera } = deviceManager.getDeviceState();
    const camera = activeCamera === 'default' ? null : activeCamera;
    this._startVideoWithVB(camera);
  }

  _startVideoWithVB(camera) {
    const dpiObject = getVideoDPI();
    const { backgroundIdx } = getBackgroundConfigFromStorage();
    prepareBg(backgroundIdx).then((bgdom) => {
      this.sendSocket(AVNotifyMediaSDKTypes.START_VIDEO_VB_SETTING, {
        canvas: PREVIEW_CANVAS,
        bgdom: bgdom,
        width: dpiObject.width,
        height: dpiObject.height,
        ssid: 0,
        VideoSelectValue: camera,
      });
    });
  }

  stopVideo() {
    const msg = AVNotifyMediaSDKTypes.STOP_VIDEO_VB_SETTING;
    return this.sendSocket(msg, {
      isSwitch: false,
    });
  }

  enableReuseStream(flag) {
    this.sendSocket(AVNotifyMediaSDKTypes.ENABLE_REUSE_STREAM, {
      enable: flag,
    });
  }

  changeCamera(deviceId) {
    this.sendSocket(AVNotifyMediaSDKTypes.CHANGE_VIDEO_CAPTURE_DEVICE, {
      VideoSelectValue: deviceId === 'default' ? null : deviceId,
    });
  }
}

export default AVSocket;
