export const START_MEDIA = 0;
export const ADD_RENDER_VIDEO = 1;
export const STOP_RENDER_VIDEO = 2;
export const START_CAPTURE_VIDEO = 3;
export const STOP_CAPTURE_VIDEO = 4;
export const ADD_RENDER_AUDIO = 5;
export const ADD_CAPTURE_AUDIO = 7;
export const STOP_CAPTURE_AUDIO = 8;
export const CHANGE_FRAME_RATE = 9;
export const CHANGE_VIDEO_RESOLUTION = 10;
export const CHANGE_AUDIO_SPEAKER = 11;
export const CHANGE_VIDEO_CAPTURE_DEVICE = 12;
export const UPDATE_CANVAS_SIZE = 46;
export const LEAVE_MEETING = 15;
export const MEETING_FAIL_OVER = 16;
export const END_MEDIA = 17;
export const CHANGE_AUDIO_MIC = 18;
export const WEBRTC_RESTART = 19;
export const LEAVE_COMPUTER_AUDIO = 21;
export const JOIN_COMPUTER_AUDIO = 22;
export const START_SHARING = 23;
export const STOP_SHARING = 24;
export const SWITCH_CANVAS_FOR_VIDEO_CAPTURE = 25;
export const START_REMOTE_CONTROL = 26;
export const UPDATE_REMOTE_CONTROL_PROPERTIES = 27;
export const CANCEL_REMOTE_CONTROL = 28;
export const CHANGE_CURRENT_SHARING_ACTIVE_SSRC = 36;
export const COMMAND_SOCKET_MESSAGE_NOTIFY = 40;
export const AES_GCM_IV_VALUE = 42;
export const USER_NODE_LIST = 43;
export const PAUSE_OR_RESUME_AUDIO_DECODE = 45;
export const ZOOM_RENDER_VIDEO = 48;
export const USER_NODE_AUDIO_STATUS_LIST = 51;
export const MOVE_PTZ_CAMERA = 53;
export const START_STOP_REMOTE_CONTROL_CHECK = 54;

export const VIDEO_MASK_SETTING = 62;
export const UPDATE_BG_IMAGE = 63;
export const UPDATE_MASK_INFO = 64;
export const FINISH_MASK_SETTING = 65;

export const START_VIDEO_VB_SETTING = 91;
export const UPDATE_VIDEO_VB_BG_IMAGE = 92;
export const STOP_VIDEO_VB_SETTING = 93;

export const VIDEO_ENABLE_HW_RECEIVING = 70; // hardware acceleration for receiving video
export const VIDEO_ENABLE_HW_SENDING = 71; // hardware acceleration for sending video
export const JOIN_DESKTOP_AUDIO = 72;
export const LEAVE_DESKTOP_AUDIO = 73;
export const SET_DESKTOP_VOLUME = 74; // pass 0 or 100 to mute or unmute other's share
export const MIRROR_MY_VIDEO = 75;
export const WEBGL_LOST_REPLACE_CANVAS = 77;

export const USER_NODE_LIST_IN_MAIN_SESSION = 80;
export const UPDATE_MEDIA_PARAMS = 81;
export const SHARING_ADD_REV_CHANNEL_TYPE = 82;
export const SHARING_REMOVE_REV_CHANNEL_TYPE = 83;
export const BUILD_MS_CHANNEL_IN_BO = 84;
export const BUILD_MA_CHANNEL_IN_BO = 85;
export const ENABLE_SHARE_TO_BO = 86;
export const ENABLE_BROADCAST_TO_BO = 87;

export const SWITCH_WATER_MARK_FLAG = 90;

export const ENABLE_VIDEO_OBSERVER = 99;
export const CHANGE_HID_ENABLE = 100;
export const SWITCH_SHARING_TYPE = 101;
export const NEW_ACTIVE_SPEAKER_SSRC = 110;

export const CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK = 112;
/** This is for notifying media sdk 4098 rwg signal received, media sdk can send message to rwg now */
export const NOTIFY_SDK_JOIN_RWG_SUCCESS = 113;

export const WHITEBOARD_MESSAGE = 120;
export const AUDIO_DENOISE_SWITCH = 121;
export const CHANGE_STEREO_ENABLE = 125;
export const UPDATE_VIDEOHD_VALUE = 'update_videohd_value';
export const ENABLE_REUSE_STREAM = 'ENABLE_REUSE_STREAM';
export const DESTORY_REUSE_STREAM = 'DESTORY_REUSE_STREAM';
export const SET_CODEC_MODE = 122;
export const SAVE_LOCAL_LOG = 125;

export const CHANGE_AUDIO_PROFILE = 126;
export const RWG_COMMAND_BYPASS_TO_WCL = 128;
export const USER_HOLD_STATUS_SWITCH = 136;
